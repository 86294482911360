import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import {
    getMissionsKayloo,
    getPortailsKayloo,
    getModulesKayloo, getSocials
} from "../utils/Common";
import Link from "gatsby-link";
import {StaticImage} from "gatsby-plugin-image";
import KaylooModules from "../components/layout/KaylooModules";

const social = getSocials();

const SeproKayloo = () => {
    const {t} = useTranslation();
    const d = new Date();
    let year = d.getFullYear();
    const missions = getMissionsKayloo();
    const portails = getPortailsKayloo();
    return (
        <Layout pageTitle={t("sepro_solution") + " - " + t("sepro_kayloo")}>
            <div className="min-h-full bg-kayloo">
                <NavBar active={'solution'} page="solution" label={t("sepro_solution")}/>
                <div className="px-4 pt-50  z-50 bg-sepro-kayloo-header">
                    <div className="max-w-8xl">
                        <div className="relative flex flex-col max-w-md text-left lg:mb-32">
                            <h1 className="max-w-3xl text-3xl font-bold tracking-tight lg:text-5xl space-x-6 text-sepro-1 font-family-athena-title mt-10">{t("sepro_kayloo")}</h1>
                            <h1 className="max-w-2xl text-1xl font-medium tracking-tight lg:text-2xl space-x-6 text-sepro-1 my-8 font-family-athena-subtitle">{t("sepro_kayloo_title_1")}</h1>
                            <p className="mt-2 text-sm md:text-base text-gray-900 font-family-athena-p">{t("sepro_kayloo_msg_1")}</p>
                            <p className="mt-2 text-sm md:text-base text-gray-900 font-family-athena-p z-50 mb-10 md:mb-20 sm:mb-20">{t("sepro_kayloo_msg_11")}</p>
                        </div>
                    </div>
                </div>
                <div className="md:-mt-32  md:pb-44 sm:pb-20  bg-kayloo-2 z-20">
                    <div
                        className="relative flex flex-col items-center max-w-3xl px-6 py-5 sm:py-10 mx-auto text-center sm:my-15 lg:px-0 z-20">
                        <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-200 lg:text-5xl mt-8 md:mt-20 z-50">{t("why")}
                            <span className=""> Kayl<span className="text-red-500">oo</span> ?</span></h1>
                        <p className="max-w-full mt-10 text-sm md:text-base text-gray-200 text-justify mb-36 md:mb-20">{t("sepro_kayloo_msg_2")}</p>
                    </div>
                </div>
                <div className="relative flex flex-col items-center max-w-3xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0 -mt-36 md:-mt-40">
                    <StaticImage src={"../../static/kayloo/kayloo-banner.png"} alt={t("sepro_kayloo")}
                                 className="object-center object-cover md:-mt-36 md:max-w-7xl"/>

                </div>
                <div className="relative flex flex-col items-center px-4 pb-10 md:pb-20">
                    <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-sepro-1 lg:text-5xl">{t("the_modules")}</h1>
                    <p className="hidden max-w-3xl mt-10 text-sm md:text-base text-gray-400 text-justify mb-20">{t("sepro_kayloo_msg_3")}</p>
                    <KaylooModules/>

                </div>
                <div className="relative flex flex-col items-center px-4 bg-kayloo-2 pb-20">
                    <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-100 lg:text-5xl mt-20">{t("sepro_espace")}
                        <span> Clients Kayl<span className="text-red-500">oo</span></span></h1>
                    <p className="max-w-3xl mt-10 text-sm md:text-base text-gray-400 text-center mb-20">{t("sepro_kayloo_msg_4")}</p>
                    <div className="grid grid-cols-1 md:gap-8 items-start md:grid-cols-3 lg:grid-cols-3 lg:gap-16">
                            <div className="grid grid-cols-1 mb-5">
                                <div
                                    className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                    <div aria-hidden="true">
                                        <StaticImage
                                            src={"../../static/kayloo/locataire.png"}
                                            alt={t('portail_2')}
                                            className="object-cover object-center mx-auto"
                                        />
                                    </div>
                                </div>
                                <h1 className="max-w-1xl text-3xl font-bold tracking-tight text-gray-100 lg:text-2xl mt-4 text-center">{t('portail_2')}</h1>
                            </div>

                        <div className="grid grid-cols-1 mb-5">
                            <div
                                className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../static/kayloo/bailleur.png"}
                                        alt={t('portail_3')}
                                        className="object-cover object-center mx-auto"
                                    />
                                </div>
                            </div>
                            <h1 className="max-w-1xl text-3xl font-bold tracking-tight text-gray-100 lg:text-2xl mt-4 text-center">{t('portail_3')}</h1>
                        </div>
                        <div className="grid grid-cols-1 mb-5">
                            <div
                                className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../static/kayloo/syndic.png"}
                                        alt={t('portail_4')}
                                        className="object-cover object-center mx-auto"
                                    />
                                </div>
                            </div>
                            <h1 className="max-w-1xl text-3xl font-bold tracking-tight text-gray-100 lg:text-2xl mt-4 text-center">{t('portail_4')}</h1>
                        </div>

                    </div>
                </div>
                {/*kayloo footer*/}
                <div className="bg-kayloo-footer">
                    <div className="">
                        <div className="pt-5 md:pt-28 md:pb-96 z-50">
                            <div
                                className="relative flex flex-col items-center max-w-4xl px-6 py-3 mx-auto text-center sm:py-6 lg:px-0 mt-10 md:mb-32 z-50">
                                <h1 className="max-w-2xl text-3xl font-bold tracking-wide  text-white lg:text-5xl uppercase">{t("your_platform")} {' Digitale'}</h1>
                                <h1 className="max-w-2xl text-3xl font-bold tracking-wide text-cyan-500 lg:text-5xl uppercase">{t("property_management")}</h1>
                                <p className="mb-14 md:mb-20 text-base md:text-2xl text-gray-900 list-disc text-justify">
                                    <span className="mt-2 list-iteme text-white">{t("sepro_kayloo_msg_6")}</span>
                                </p>
                                <Link
                                    to={'/contact'}
                                    className="inline-block px-8 py-3 mt-8 text-sm md:text-base font-medium bg-cyan-500 border border-transparent text-slate-100 hover:bg-teal-600 uppercase mb-48 z-50 bg-opacity-100"
                                >
                                    {t("contact_us")}
                                </Link>
                            </div>
                        </div>
                        <footer className="bg-transparent">
                            <div
                                className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                                <div className="mt-8 flex justify-center space-x-6">
                                    {social.map((item) => (
                                        <a key={item.name} href={item.href}
                                           className="text-gray-400 hover:text-gray-300" target="_blank"
                                           rel="noreferrer">
                                            <span className="sr-only">{item.name}</span>
                                            <item.icon className="h-10 w-10" aria-hidden="true"/>
                                        </a>
                                    ))}
                                </div>
                                <p className="mt-8 text-center text-sm text-gray-400">&copy; {year} {t("copyright")}</p>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
            <ReactTooltip/>
        </Layout>
    );
}

export default SeproKayloo;
