import {useTranslation} from "react-i18next";
import {Link} from "gatsby";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";


export default function KaylooModules() {
    const {t} = useTranslation();
    return (
        <div className="grid grid-cols-2 gap-4 items-start sm:grid-cols-3 md:grid-cols-4 lg:gap-8 mt-20">

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module1.svg"}
                                    alt={t('module_1')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_1')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module2.svg"}
                                    alt={t('module_2')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_2')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module2.svg"}
                                    alt={t('module_3')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_3')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module2.svg"}
                                    alt={t('module_4')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_4')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static//kayloo/module5.svg"}
                                    alt={t('module_5')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_5')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module6.svg"}
                                    alt={t('module_6')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_6')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module3.svg"}
                                    alt={t('module_8')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-6 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_8')}</h2>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mb-10">
                <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                    <div
                        className="lg:col-start-1 xl:col-start-1 md:mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4">
                        <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                            <div aria-hidden="true">
                                <StaticImage
                                    src={"../../../static/kayloo/module7.svg"}
                                    alt={t('module_7')}
                                    className="object-cover object-center w-24 h-24 mx-auto "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start- flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4 mx-auto">
                        <h2 className="max-w-1xl text-sm md:text-base font-medium text-sepro-1 mb-8 mt-8 mx-auto">{t('module_7')}</h2>
                    </div>
                </div>
            </div>
        </div>

)
}